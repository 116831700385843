import React from 'react'
 import { Link } from 'react-router-dom'
import "./Social.css"
const Social = () => {
  return (
    <>
    <div className="icon-bar">
      
  <Link  to="https://api.whatsapp.com/send?phone=+923410752316&text=Hello%20Muhammadi%20Traders"  className="whatsapp a1" target="_blank"><i className="bi bi-whatsapp"></i></Link> 


  <Link to="https://www.facebook.com/" target="_blank" className="facebook a1"><i className="bi bi-facebook"></i></Link>

  {/* <Link to="#" className="twitter a1" target="_blank"><i className="fa fa-twitter"></i></Link>  */}

  {/* <Link to="https://www.youtube.com/" className="Instragram a1" target="_blank"><i className="bi bi-youtube"></i></Link>   */}

  <Link to="https://www.instagram.com/" className="Instragram a1" target="_blank"><i className="bi bi-instagram"></i></Link>

  {/* <Link to="#" className="youtube a1" target="_blank"><i className="bi bi-youtube"></i></Link>  */}
</div>
    </>
  )
}

export default Social