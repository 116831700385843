import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom';
import '../App.css'
import door from './pixels-images/05.jpg'
import panel from './pixels-images/07.jpg'
import ceiling from './pixels-images/18.jpg'
import floor from './pixels-images/17.jpg'
import blinds from './pixels-images/14.jpg'
import wallpaper from './pixels-images/12.jpg'

const Services = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  useEffect(() => {
    document.title = "Muhammadi Traders | Services";
  }, []);
  
  return (
    <>
      <Helmet>
        <meta name="description" content="Discover top-notch interior woodworking services with Muhammadi Traders. We specialize in custom wooden doors, flooring installation, ceiling woodwork, stylish window blinds, and elegant wooden paneling. Enhance your home's interior with our expert craftsmanship and quality wooden enhancements." />
      </Helmet>

    {/* <!-- Services Section --> */}
    <section id="services" className="services section">

      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p>Elegance Work In<br/></p>
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container" data-aos="fade-up" data-aos-delay="100">

        <div className="row gy-5">

          <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="200">
            <div className="service-item">
              <div className="img">
                <img src={door} className="img-set" alt=""/>
              </div>
              <div className="details position-relative">
                <div className="icon">
                  <i className="bi bi-info"></i>
                </div>
                <Link onClick={scrollToTop}  to="/DoorsGalary" className="stretched-link remove-underline">
                  <h3>Doors</h3>
                </Link>
                <p>Our doors combine elegance and durability, crafted to make a stunning entrance into any space..</p>
              </div>
            </div>
          </div>
          {/* <!-- End Service Item --> */}

          <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="300">
            <div className="service-item">
              <div className="img">
                <img src={ceiling} className="img-set" alt=""/>
              </div>
              <div className="details position-relative">
                <div className="icon">
                  <i className="bi bi-info"></i>
                </div>
                <Link onClick={scrollToTop}  to="/CeilingsGalary" className="stretched-link remove-underline">
                  <h3>Ceilings</h3>
                </Link>
                <p>Our ceilings transform spaces with sophisticated designs and impeccable craftsmanship..</p>
              </div>
            </div>
          </div>
          {/* <!-- End Service Item --> */}

          <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div className="service-item">
              <div className="img">
                <img src={panel} className="img-set" alt=""/>
              </div>
              <div className="details position-relative">
                <div className="icon">
                  <i className="bi bi-info"></i>
                </div>
                <Link onClick={scrollToTop}  to="/PanelingGalary" className="stretched-link remove-underline">
                  <h3>Paneling</h3>
                </Link>
                <p>Our paneling adds a touch of refined elegance and character to any interior.</p>
              </div>
            </div>
          </div>
          {/* <!-- End Service Item --> */}

          <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div className="service-item">
              <div className="img">
                <img src={floor} className="img-set" alt=""/>
              </div>
              <div className="details position-relative">
                <div className="icon">
                  <i className="bi bi-info"></i>
                </div>
                <Link onClick={scrollToTop}  to="/FloorMatGalary" className="stretched-link remove-underline">
                  <h3>Floor Mat</h3>
                </Link>
                <p>Our flooring combines durability and style, providing a timeless foundation for your space.</p>
              </div>
            </div>
          </div>
          {/* <!-- End Service Item --> */}

          <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div className="service-item">
              <div className="img">
                <img src={blinds} className="img-set" alt=""/>
              </div>
              <div className="details position-relative">
                <div className="icon">
                  <i className="bi bi-info"></i>
                </div>
                <Link onClick={scrollToTop}  to="/WindowBlindsGalary" className="stretched-link remove-underline">
                  <h3>Window Blinds</h3>
                </Link>
                <p>Enhance your privacy and style with window blinds that offer both elegance and functionality.</p>
              </div>
            </div>
          </div>
          {/* <!-- End Service Item --> */}

          <div className="col-xl-4 col-md-6" data-aos="zoom-in" data-aos-delay="400">
            <div className="service-item">
              <div className="img">
                <img src={wallpaper} className="img-set" alt=""/>
              </div>
              <div className="details position-relative">
                <div className="icon">
                  <i className="bi bi-info"></i>
                </div>
                <Link onClick={scrollToTop}  to="/WallpapersGalary" className="stretched-link remove-underline">
                  <h3>Wallpaper</h3>
                </Link>
                <p>Transform your space with stunning wallpaper that adds personality and charm to every room.</p>
              </div>
            </div>
          </div>
          {/* <!-- End Service Item --> */}


        </div>
      </div>
    </section>
    {/* <!-- /Services Section --> */}
    </>
  )
}

export default Services