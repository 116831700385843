import React, {useEffect} from 'react'
import '../App.css';

import image1 from './images/wi1.jpg'
import image2 from './images/wi2.jpg'
import image3 from './images/wi3.jpg'
import image4 from './images/wi4.jpg'
import image5 from './images/wi5.jpg'
import image6 from './images/wi6.jpg'
import file1 from './files/wf1.pdf'
import file2 from './files/wf2.pdf'

const WallpapersGalary = () => {

    useEffect(() => {
        document.title = "Muhammadi Traders | Wallpapers";
      }, []);

  return (
    <>
    {/* <!-- Portfolio Section --> */}
    <section id="portfolio" className="portfolio section">

      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Wallpapers</h2>
        <p>CHECK OUR DESIGNS FOR WALLPAPERS</p>
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container">

            <div className="row gy-4 isotope-container" data-aos="fade-up" data-aos-delay="200">

                <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                <div className="portfolio-content h-100">
                    <img src={image1} className="img-fluid" alt=""/>
                </div>
                </div>
                {/* <!-- End Portfolio Item --> */}

                <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                <div className="portfolio-content h-100">
                    <img src={image2} className="img-fluid" alt=""/>
                </div>
                </div>
                {/* <!-- End Portfolio Item --> */}

                <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                <div className="portfolio-content h-100">
                    <img src={image3} className="img-fluid" alt=""/>
                </div>
                </div>
                {/* <!-- End Portfolio Item --> */}

                <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                <div className="portfolio-content h-100">
                    <img src={image4} className="img-fluid" alt=""/>
                </div>
                </div>
                {/* <!-- End Portfolio Item --> */}

                <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                <div className="portfolio-content h-100">
                    <img src={image5} className="img-fluid" alt=""/>
                </div>
                </div>
                {/* <!-- End Portfolio Item --> */}

                <div className="col-lg-4 col-md-6 portfolio-item isotope-item filter-app">
                <div className="portfolio-content h-100">
                    <img src={image6} className="img-fluid" alt=""/>
                </div>
                </div>
                {/* <!-- End Portfolio Item --> */}

                <div className='w-100 text-center' data-aos="fade-up" data-aos-delay="300">
                        <a href={file1} download='file.pdf' target='_blank' className="btn-get-started-pdf">Download Complete Branded Wallpaper Range</a>
                </div>

                <div className='w-100 text-center' data-aos="fade-up" data-aos-delay="300">
                        <a href={file2} download='file.pdf' target='_blank' className="btn-get-started-pdf">Download Complete Local Wallpaper Range</a>
                </div>

            </div>
            {/* <!-- End Portfolio Container --> */}

        </div>

    </section>
    {/* <!-- /Portfolio Section --> */}
    </>

  )
}

export default WallpapersGalary