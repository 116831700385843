import React from 'react'
import {Link} from 'react-router-dom';
import '../App.css';
import logo from './images/logo.png'

const Footer = () => {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };


  return (
    <>
    <footer id="footer" className="footer dark-background">

    <div className="container footer-top">
    <div className="row gy-4">
        <div className="col-lg-4 col-md-6 footer-about">
        <Link onClick={scrollToTop}  to="" className="logo d-flex align-items-center footer-company-name">
            <span className="sitename">MUHAMMADI TRADERS</span>
        </Link>
        <div className="footer-contact">
            <p>Jaranwala Road near Gate # 1</p>
            <p>Al Rehman Garden Ph.2</p>
            <p>Sharqpur Road Lahore</p>
            <p><strong>Phone:</strong> <span>+92 341 075 2316</span></p>
            <p><strong>Email:</strong> <span>info@muhammaditraders.com</span></p>
        </div>
        <div className="social-links d-flex">
            <Link onClick={scrollToTop}  to=""><i className="bi bi-facebook"></i></Link>
            <Link onClick={scrollToTop}  to=""><i className="bi bi-instagram"></i></Link>
        </div>
        </div>

        <div className="col-lg-1 col-md-2">
        </div>

        {/* useful links section */}
        <div className="col-lg-2 col-md-3 footer-links">
        <h4>Useful Links</h4>
        <ul>
            <li><i className="bi bi-chevron-right"></i> <Link onClick={scrollToTop}  to="">Home</Link></li>
            <li><i className="bi bi-chevron-right"></i> <Link onClick={scrollToTop}  to="about">About Us</Link></li>
            <li><i className="bi bi-chevron-right"></i> <Link onClick={scrollToTop}  to="services">Services</Link></li>
            <li><i className="bi bi-chevron-right"></i> <Link onClick={scrollToTop}  to="contact">Contact Us</Link></li>
        </ul>
        </div>

        <div className="col-lg-2 col-md-3">
        </div>
        

        <div className="col-lg-2 col-md-3">
        <img src={logo} alt='LOGO' className='footer-img'/>
        </div>

        {/* <div className="col-lg-2 col-md-3 footer-links">
        <h4>Our Services</h4>
        <ul>
            <li><i className="bi bi-chevron-right"></i> <Link onClick={scrollToTop}  to="#">Web Design</Link></li>
            <li><i className="bi bi-chevron-right"></i> <Link onClick={scrollToTop}  to="#">Web Development</Link></li>
            <li><i className="bi bi-chevron-right"></i> <Link onClick={scrollToTop}  to="#">Product Management</Link></li>
            <li><i className="bi bi-chevron-right"></i> <Link onClick={scrollToTop}  to="#">Marketing</Link></li>
            <li><i className="bi bi-chevron-right"></i> <Link onClick={scrollToTop}  to="#">Graphic Design</Link></li>
        </ul>
        </div> */}

        {/* <div className="col-lg-4 col-md-12 footer-newsletter">
        <h4>Our Newsletter</h4>
        <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
        <form action="forms/newsletter.php" method="post" className="php-email-form">
            <div className="newsletter-form"><input type="email" name="email"/><input type="submit" value="Subscribe"/></div>
            <div className="loading">Loading</div>
            <div className="error-message"></div>
            <div className="sent-message">Your subscription request has been sent. Thank you!</div>
        </form>
        </div> */}

        <div className="text-center credits">
        © Copyright <strong>Muhammadi Traders</strong> All Rights Reserved
        <br/>
        Designed by <Link onClick={scrollToTop}  style={{textDecoration:'none', color:'#FFF'}} to="https://divcodex.com/">DivCodeX</Link>
        </div>

    </div>
    </div>

    </footer>
    {/* <!-- Scroll Top --> */}
    <Link onClick={scrollToTop}  to="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link>

    

    </>
  )
}

export default Footer