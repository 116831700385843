import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Hero from './Components/Hero'
import About from './Components/About'
import Services from './Components/Services'
import Contact from './Components/Contact'

const HomePage = () => {

  useEffect(() => {
    document.title = "Muhammadi Traders | Home";
  }, []);

  return (
    <>

      <Helmet>
        <meta name="description" content="Discover top-notch interior woodworking services with Muhammadi Traders. We specialize in custom wooden doors, flooring installation, ceiling woodwork, stylish window blinds, and elegant wooden paneling. Enhance your home's interior with our expert craftsmanship and quality wooden enhancements." />
      </Helmet>
      
    <Hero/>
    <About/>
    <Services/>
    <Contact/>
    </>
  )
}

export default HomePage