import React, {useState} from 'react';
// import '../App.css';
import {Link, NavLink} from 'react-router-dom';
import {FaBars, FaTimes} from 'react-icons/fa'
import './Header.css'
import logo from './images/logo.png'



const Header = () => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)

  return (
    <>
    {/* <header id="header" className="header d-flex align-items-center fixed-top"
    style={{backgroundColor: '#000000'}}>
    <div className="container-fluid container-xl position-relative d-flex align-items-center">

      <Link to="index.html" className="logo d-flex align-items-center me-auto">
        <!-- Uncomment the line below if you also wish to use an image logo -->
        <!-- <img src="assets/img/logo.png" alt=""> -->
        <h1 className="sitename">Dewi</h1>
      </Link>

      <nav id="navmenu" className="navmenu">
        <ul>
          <li><Link to="" className="active">Home</Link></li>
          <li><Link to="about">About</Link></li>
          <li><Link to="services">Services</Link></li>
          <li><Link to="portfolio">Portfolio</Link></li>
          <li><Link to="team">Team</Link></li>
          <li><Link to="contact">Contact</Link></li>
        </ul>
        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
      </nav>

    </div>
    </header> */}


    <div className='header mb-0' style={{backgroundColor:'rgba(0, 0, 0, 0.8)'}}>

        <div className='container'>

            <img src={logo} alt='LOGO' className='logo'/>
            

            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              
              {/* <li className='active'><Link to="">Home</Link></li> */}
              <li><NavLink onClick={scrollToTop}  to="" exact activeClassName="active">Home</NavLink></li>

              {/* <li><Link to="about">About</Link></li> */}
              <li><NavLink onClick={scrollToTop}  to="about" exact activeClassName="active">About</NavLink></li>

              {/* <li><Link to="services">Services</Link></li> */}
              <li><NavLink onClick={scrollToTop}  to="services" exact activeClassName="active">Services</NavLink></li>

              {/* <li><Link to="galary">Galary</Link></li> */}
              {/* <li><NavLink onClick={scrollToTop}  to="galary" exact activeClassName="active">Galary</NavLink></li> */}


              {/* <li><Link to="team">Team</Link></li> */}

              {/* <li><Link to="contact">Contact</Link></li> */}
              <li><NavLink onClick={scrollToTop}  to="contact" exact activeClassName="active">Contact</NavLink></li>
            </ul>

            {/* <div className='btn-group'>
                <button className='btn'>Connect Wallet</button>
            </div> */}
            <div className='hamburger' onClick={handleClick}>
                {click ? (<FaTimes size={20} style={{color: '#333'}}/>) : (<FaBars size={20} style={{color: '#333'}} />)}

            </div>
        </div>
    </div>

    </>
  )
}

export default Header;
