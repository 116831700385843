import React from 'react';
import {Link} from 'react-router-dom';
import '../App.css';
import DownloadPDF from './DownloadPDF';
import heroImage from './pixels-images/16.jpg'




const Hero = () => {

  return (
    <>

    {/* <!-- Hero Section --> */}
    <section id="hero" className="hero section dark-background">

      <img src={heroImage} alt="" data-aos="fade-in"/>

      <div className="container d-flex flex-column align-items-center">
        <h2 data-aos="fade-up" data-aos-delay="100">PLAN. DESIGN. CREATE.</h2>
        <p data-aos="fade-up" data-aos-delay="200">We are ambitious to make your house a dream home with our creativity</p>
        <div className="d-flex" data-aos="fade-up" data-aos-delay="300">
          {/* <Link to="#about" className="btn-get-started" onClick={() => scrollToId('about')}>Get Started</Link> */}
          {/* <DownloadPDF/> */}
          {/* <Link to="https://www.youtube.com/watch?v=Y7f98aduVJ8" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></Link> */}
        </div>
      </div>

    </section>
    {/* <!-- /Hero Section --> */}

    </>
  )
}

export default Hero