import React from 'react';
import '../App.css';


function DownloadPDF(file) {
  const fileURL = String(file)
  console.log(fileURL)
  return (
    <div>
      <a href={fileURL} download={fileURL} target='_blank' className="btn-get-started">Download PDF</a>
    </div>
  );
}

export default DownloadPDF;