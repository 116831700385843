import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import HomePage from './HomePage';
import About from './Components/About'
import Services from './Components/Services'
import Contact from './Components/Contact'

import DoorsGalary from './Components/DoorsGalary';
import CeilingsGalary from './Components/CeilingsGalary';
import PanelingGalary from './Components/PanelingGalary';
import FloorMatGalary from './Components/FloorMatGalary';
import WindowBlindsGalary from './Components/WindowBlindsGalary';
import WallpapersGalary from './Components/WallpapersGalary';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='' element={<HomePage />} />
      <Route path='about' element={<About />} />
      <Route path='services' element={<Services />} />
      <Route path='/DoorsGalary' element={<DoorsGalary />} />
      <Route path='/CeilingsGalary' element={<CeilingsGalary />} />
      <Route path='/PanelingGalary' element={<PanelingGalary />} />
      <Route path='/FloorMatGalary' element={<FloorMatGalary />} />
      <Route path='/WindowBlindsGalary' element={<WindowBlindsGalary />} />
      <Route path='/WallpapersGalary' element={<WallpapersGalary />} />
      <Route path='contact' element={<Contact />} />
    </Route>
  )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
