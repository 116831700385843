import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import {Link} from 'react-router-dom';
import '../App.css';
import image1 from './pixels-images/06.jpg'
import image2 from './pixels-images/09.jpg'


const About = () => {

  useEffect(() => {
    document.title = "Muhammadi Traders | About Us";
  }, []);


  return (
    <>
      <Helmet>
        <meta name="description" content="Discover top-notch interior woodworking services with Muhammadi Traders. We specialize in custom wooden doors, flooring installation, ceiling woodwork, stylish window blinds, and elegant wooden paneling. Enhance your home's interior with our expert craftsmanship and quality wooden enhancements." />
      </Helmet>

      
    {/* <!-- About Section --> */}
    <section id="about" className="about section">
      
    {/* <!-- Section Title --> */}
    <div className="container section-title" data-aos="fade-up">
      <h2>About Us</h2>
    </div>
    {/* <!-- End Section Title --> */}

      <div className="container">

        <div className="row gy-4">
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h3>Muhammadi Traders excels in crafting high-quality woodwork, combining traditional craftsmanship with modern design.</h3>
            <img src={image1} className="img-fluid rounded-4 mb-4" alt=""/>
            <p>Muhammadi Traders is a renowned name in the woodworking industry, specializing in crafting premium-quality doors, ceilings, and pallings. With a deep commitment to traditional craftsmanship.</p>
            <p>We take pride in transforming raw wood into beautiful, functional pieces that enhance the aesthetic appeal of any space. Our team of skilled artisans ensures that every product is crafted with precision and attention to detail, reflecting the timeless beauty and durability of wood.</p>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="250">
            <div className="content ps-0 ps-lg-5">
              <p className="fst-italic">
              We take pride in delivering exceptional woodwork that showcases the beauty and craftsmanship of every piece we create. Choose us for 
              </p>
              <ul>
                <li><i className="bi bi-check-circle-fill"></i> <span>Unparalleled Craftsmanship</span></li>
                <li><i className="bi bi-check-circle-fill"></i> <span>Personalized Services & Designs</span></li>
                <li><i className="bi bi-check-circle-fill"></i> <span>Commitment to Deliver 100% on Time</span></li>
              </ul>
              <p>
              In addition to our craftsmanship, Muhammadi Traders is dedicated to innovation and design, blending classic techniques with modern trends. Whether you're looking to add a touch of elegance to your home with custom doors or create stunning wooden ceilings that make a statement, we provide solutions tailored to your unique style and needs. Trust Muhammadi Traders to bring your vision to life with our exceptional woodwork.
              </p>

              <div className="position-relative mt-4">
                <img src={image2} className="img-fluid rounded-4" alt=""/>
                {/* <Link to="https://www.youtube.com/watch?v=Y7f98aduVJ8" className="glightbox pulsating-play-btn"></Link> */}
              </div>
            </div>
          </div>
        </div>

      </div>

    </section>
    {/* <!-- /About Section --> */}
    </>
  )
}

export default About