
import Header from './Components/Header.jsx';
import Footer from './Components/Footer.jsx';
import Whatsapp from "./Components/Sociallinks/Whatsapp/Whatsapp.jsx"
import Social from "./Components/Sociallinks/Socialicons/Social.jsx"

import { Outlet } from 'react-router-dom'
import './App.css';

function App() {
  return (
    <>
    <div className="index-page">
      <Whatsapp/>
      <Social/>
    <Header/>
    <Outlet/>
    <Footer/>
    </div>
    </>
  );
}

export default App;
