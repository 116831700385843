import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import '../App.css';

const Contact = () => {

  useEffect(() => {
    document.title = "Muhammadi Traders | Contact Us";
  }, []);

  return (
    <>

      <Helmet>
        <meta name="description" content="Discover top-notch interior woodworking services with Muhammadi Traders. We specialize in custom wooden doors, flooring installation, ceiling woodwork, stylish window blinds, and elegant wooden paneling. Enhance your home's interior with our expert craftsmanship and quality wooden enhancements." />
      </Helmet>

    {/* <!-- Contact Section --> */}
    <section id="contact" className="contact section">

      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p>MUHAMMADI TRADERS OFFICE</p>
      </div>
      {/* <!-- End Section Title --> */}

      <div className="container" data-aos="fade-up" data-aos-delay="100">

        <div className="row gy-4">
          <div className="col-lg-6 ">
            <div className="row gy-4">

              <div className="col-lg-12">
                <div className="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="200">
                  <i className="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p style={{textAlign:'center'}}>Jaranwala Road near Gate # 1 Al Rehman Garden Ph.2 Sharqpur Road Lahore</p>
                </div>
              </div>
              {/* <!-- End Info Item --> */}

              <div className="col-md-6">
                <div className="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="300">
                  <i className="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>+92 341 075 2316</p>
                </div>
              </div>
              {/* <!-- End Info Item --> */}

              <div className="col-md-6">
                <div className="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="400">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@muhammaditraders.com</p>
                </div>
              </div>
              {/* <!-- End Info Item --> */}

            </div>
          </div>

          {/* <div className="col-lg-6">
            <form className="php-email-form" data-aos="fade-up" data-aos-delay="500">
              <div className="row gy-4">

                <div className="col-md-6">
                  <input type="text" name="name" className="form-control" placeholder="Your Name" required=""/>
                </div>

                <div className="col-md-6 ">
                  <input type="email" className="form-control" name="email" placeholder="Your Email" required=""/>
                </div>

                <div className="col-md-12">
                  <input type="text" className="form-control" name="subject" placeholder="Subject" required=""/>
                </div>

                <div className="col-md-12">
                  <textarea className="form-control" name="message" rows="4" placeholder="Message" required=""></textarea>
                </div>

                <div className="col-md-12 text-center">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>

                  <button type="submit">Send Message</button>
                </div>

              </div>
            </form>
          </div>
          <!-- End Contact Form --> */}

          <div className="col-lg-6">
            <form className="php-email-form" data-aos="fade-up" data-aos-delay="500">
              <div className="row gy-4">
              <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3398.2610627932527!2d74.22594440000002!3d31.599305599999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzHCsDM1JzU3LjUiTiA3NMKwMTMnMzMuNCJF!5e0!3m2!1sen!2s!4v1727083156780!5m2!1sen!2s" width="600" height='300' style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>


              </div>
            </form>
          </div>

        </div>

      </div>

    </section>
    {/* <!-- /Contact Section --> */}


    </>  
  )
}

export default Contact